import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import { useEffect, useState } from "react";
import { MainContainer } from "../styled/templates/MainContainer";
import { useMediaQuery } from "@mui/material";
import { auth, db } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import { MainPage } from "../styled/templates/MainPage";
import CircularLoader from "../styled/loaders/CircularLoader";
import { doc, setDoc } from "firebase/firestore";

const Dashboard = () => {
  const mobile = useMediaQuery("(max-width: 768px)");

  const [sidebarHidden, setSidebarHidden] = useState(mobile ? true : false);

  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (!loading && user) {
      setLoader(false);
    }

    if (loading && !user) {
      setLoader(true);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading]);

  // async function changeAddresses() {
  //   await setDoc(doc(db, "admin", "deposit"), {
  //     Regular: {
  //       apiKey: "",
  //       type: "manual",
  //       addresses: {
  //         APE: {
  //           address: "0xAD0D457Bd090827999233bF10640915065d1117B",
  //           tag: null,
  //           network: "ERC20",
  //           name: "ApeCoin",
  //           memo: null,
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7311.jpegThu%20Sep%2019%202024%2015%3A39%3A53%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=7fd1677d-1d6e-4d3a-bdf2-7f24f37f2d77",
  //           note: null,
  //           symbol: "APE",
  //         },
  //         ETH: {
  //           memo: null,
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7288.jpegThu%20Sep%2019%202024%2012%3A53%3A57%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=09793e05-27e1-4771-a814-eb84d90c25ef",
  //           name: "Ethereum",
  //           symbol: "ETH",
  //           address: "0xAD0D457Bd090827999233bF10640915065d1117B",
  //           tag: null,
  //           note: null,
  //           network: "Ethereum Network ",
  //         },
  //         USDC: {
  //           memo: null,
  //           note: null,
  //           name: "USD Coin",
  //           address: "0x6332cba495A2246E2231Efba22480e2B3BcCF630",
  //           symbol: "USDC",
  //           network: "ERC 20",
  //           tag: null,
  //           QR: null,
  //         },
  //         PNG: {
  //           memo: null,
  //           name: "Pangolin",
  //           symbol: "PNG",
  //           address: "0x6332cba495A2246E2231Efba22480e2B3BcCF630",
  //           tag: null,
  //           note: null,
  //           QR: null,
  //           network: "Avalanche  C chain ",
  //         },
  //         FET: {
  //           note: null,
  //           tag: null,
  //           QR: null,
  //           memo: null,
  //           address: "0x6332cba495A2246E2231Efba22480e2B3BcCF630",
  //           symbol: "FET",
  //           name: "Artificial Superintelligence Alliance",
  //           network: "ETHEREUM NETWORK",
  //         },
  //         HBAR: {
  //           note: null,
  //           address: "0.0.6493757",
  //           network: null,
  //           name: "Hedera",
  //           tag: null,
  //           memo: null,
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_6095.jpegWed%20Jul%2031%202024%2015%3A19%3A07%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=794ad777-f7b7-464f-8dd3-d7b6d6615878",
  //           symbol: "HBAR",
  //         },
  //         XRP: {
  //           name: "Ripple",
  //           memo: null,
  //           address: "rG16WTd8P5mDGXCm2CCxUYwBqEuDYrvqEW",
  //           symbol: "XRP",
  //           tag: null,
  //           note: null,
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_6987.jpegSat%20Sep%2007%202024%2020%3A28%3A40%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=6b70548d-e0bb-45ba-abc9-e2753eb03588",
  //           network: null,
  //         },
  //         RNDR: {
  //           name: "Render",
  //           QR: "https://firebasestorage.googleapis.com/v0/b/hexa-finex.appspot.com/o/IMG_4691.jpegSat%20Jun%2001%202024%2022%3A02%3A44%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=524d1843-5e85-4c08-a41e-03f4f386ee2d",
  //           note: null,
  //           symbol: "RNDR",
  //           tag: null,
  //           address: "AchoHB1oEU3419GGy91eDszzH6JYocZfNTSj9d73ZcNY",
  //           memo: null,
  //           network: "SOL",
  //         },
  //         XMR: {
  //           symbol: "XMR",
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7352.jpegThu%20Sep%2019%202024%2021%3A24%3A06%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=4ac3bdad-0be1-48a5-9688-26c00be5ed02",
  //           memo: null,
  //           name: "Monero",
  //           tag: null,
  //           network: null,
  //           address:
  //             "44eiF8eb2Qkca98HpCNXvKAwgJXmqyyFHepaNnrWBWeuWSXLbfBvhXQ7GZ1iBdjHS8Fxi55M2coNojPrgDm6p82J4WY691G",
  //           note: null,
  //         },
  //         DASH: {
  //           symbol: "DASH",
  //           note: null,
  //           memo: null,
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7308.jpegThu%20Sep%2019%202024%2015%3A35%3A59%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=dc639430-de26-4c4f-a286-c2dcba6724ed",
  //           network: null,
  //           name: "Dash",
  //           tag: null,
  //           address: "XgaARt88qJgJhoLz6pYugG57BhMJA1h6SV",
  //         },
  //         AVAX: {
  //           network: null,
  //           tag: null,
  //           QR: null,
  //           note: null,
  //           symbol: "AVAX",
  //           name: "Avalanche",
  //           memo: null,
  //           address: "0x6332cba495A2246E2231Efba22480e2B3BcCF630",
  //         },
  //         HEX: {
  //           network: "ETH",
  //           memo: null,
  //           symbol: "HEX",
  //           name: "Hex",
  //           address: "0x6332cba495A2246E2231Efba22480e2B3BcCF630",
  //           tag: null,
  //           note: null,
  //           QR: null,
  //         },
  //         LINK: {
  //           name: "ChainLink",
  //           tag: null,
  //           note: null,
  //           symbol: "LINK",
  //           address: "0x031dB9cd5203F4Bf9F1F22AC9b85476667Fae71B",
  //           network: "Ethereum Network ",
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7312.jpegThu%20Sep%2019%202024%2015%3A42%3A02%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=99e69e8a-b95f-4a22-bdbf-b6bf70f2b214",
  //           memo: null,
  //         },
  //         SHIB: {
  //           network: "ERC20",
  //           name: "Shiba Inu",
  //           symbol: "SHIB",
  //           address: "0x6332cba495A2246E2231Efba22480e2B3BcCF630",
  //           memo: null,
  //           QR: null,
  //           note: null,
  //           tag: null,
  //         },
  //         XDC: {
  //           address: "xdc031dB9cd5203F4Bf9F1F22AC9b85476667Fae71B",
  //           memo: null,
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_6246.jpgTue%20Aug%2006%202024%2009%3A34%3A27%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=5898d529-15ae-4ce9-aa17-cc80a1917894",
  //           name: "XDC Network",
  //           note: null,
  //           symbol: "XDC",
  //           network: null,
  //           tag: null,
  //         },
  //         VET: {
  //           memo: null,
  //           name: "Vechain",
  //           symbol: "VET",
  //           QR: null,
  //           address: "0x68CDB342d78502612203C0C2E56A0B95130DDEbf",
  //           note: null,
  //           network: null,
  //           tag: null,
  //         },
  //         MATIC: {
  //           symbol: "MATIC",
  //           note: null,
  //           network: null,
  //           tag: null,
  //           address: "0x6332cba495A2246E2231Efba22480e2B3BcCF630",
  //           memo: null,
  //           QR: null,
  //           name: "Polygon",
  //         },
  //         QNT: {
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7314.jpegThu%20Sep%2019%202024%2015%3A45%3A15%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=b481107e-1d79-42f3-998e-718ccb3d86ed",
  //           symbol: "QNT",
  //           network: "Erc",
  //           name: "Quant",
  //           tag: null,
  //           note: null,
  //           address: "0x031dB9cd5203F4Bf9F1F22AC9b85476667Fae71B",
  //           memo: null,
  //         },
  //         TRX: {
  //           address: "TBR82PjxjptipW1Mmaa4uXHGCdH1msUWun",
  //           name: "Tron",
  //           note: null,
  //           memo: null,
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7317.jpegThu%20Sep%2019%202024%2015%3A58%3A00%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=27f9c1ce-37e0-48d3-afea-5634de9dae07",
  //           tag: null,
  //           network: null,
  //           symbol: "TRX",
  //         },
  //         MANA: {
  //           name: "Decentraland",
  //           tag: null,
  //           network: null,
  //           address: "0xAD0D457Bd090827999233bF10640915065d1117B",
  //           note: null,
  //           QR: null,
  //           symbol: "MANA",
  //           memo: null,
  //         },
  //         XLM: {
  //           memo: null,
  //           symbol: "XLM",
  //           note: null,
  //           address: "GD3RSQLBNOAKCVAAZ5OX7RPBK57U24YSBZEBPUMHQDHVKVFKS43SKYFA",
  //           name: "Stellar",
  //           tag: null,
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7298.jpegThu%20Sep%2019%202024%2013%3A42%3A50%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=0f5bf91c-af95-4b89-8522-72dcf2dece4a",
  //           network: null,
  //         },
  //         BCH: {
  //           tag: null,
  //           address: "qrvj69w8dvxaekgwt5rnprcx2e0tusv2jg7kdr8htg",
  //           symbol: "BCH",
  //           memo: null,
  //           note: null,
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7291.jpegThu%20Sep%2019%202024%2013%3A07%3A48%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=48cbfb4d-ed51-4877-b2b2-8270f85483f3",
  //           name: "Bitcoin Cash",
  //           network: null,
  //         },
  //         SOL: {
  //           tag: null,
  //           address: "AchoHB1oEU3419GGy91eDszzH6JYocZfNTSj9d73ZcNY",
  //           note: null,
  //           symbol: "SOL",
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7307.jpegThu%20Sep%2019%202024%2015%3A32%3A53%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=b47466a8-58b5-45ea-a3cf-f0d6208fb485",
  //           network: null,
  //           memo: null,
  //           name: "Solana",
  //         },
  //         DOGE: {
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7306.jpegThu%20Sep%2019%202024%2015%3A29%3A50%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=163e8a8e-bea9-4ebf-aa8f-61cc65b76417",
  //           tag: null,
  //           symbol: "DOGE",
  //           note: null,
  //           network: null,
  //           name: "Dogecoin",
  //           address: "DSgL4du6LReobtpLL3tgKeVpm7mFdbHTbS",
  //           memo: null,
  //         },
  //         DAI: {
  //           symbol: "DAI",
  //           note: null,
  //           QR: null,
  //           tag: null,
  //           network: "ERC20",
  //           memo: null,
  //           name: "Dai",
  //           address: "0x6332cba495A2246E2231Efba22480e2B3BcCF630",
  //         },
  //         ICP: {
  //           note: null,
  //           network: null,
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/internet%20computer.pngThu%20Sep%2005%202024%2016%3A46%3A23%20GMT%2B0100%20(West%20Africa%20Standard%20Time)?alt=media&token=1214434a-4805-445b-add2-5f42bc9b6295",
  //           memo: null,
  //           symbol: "ICP",
  //           address:
  //             "cdc17bcb8b56e788060ee2eec9266b8a2ea206ae79011fe1434d5e891dbfb56f",
  //           name: "Internet Computer",
  //           tag: null,
  //         },
  //         ADA: {
  //           note: null,
  //           network: null,
  //           memo: null,
  //           address:
  //             "addr1q8jla45hsht5anx7hhsfh9pe092nn3pkcy6aeutmj2clg6xty6vrf7w77lcf9exwnjpca33ujy5rhvzq9mucaw50e3jsx74l79",
  //           QR: null,
  //           name: "Cardano",
  //           tag: null,
  //           symbol: "ADA",
  //         },
  //         BTC: {
  //           symbol: "BTC",
  //           name: "Bitcoin",
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7300.jpegThu%20Sep%2019%202024%2013%3A48%3A25%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=bddb5bfd-ac0d-402d-8e85-56036d054851",
  //           memo: null,
  //           address: "1852Yq8nRoS1JZ15xUX4v9jbzs1rz6nVsU",
  //           note: null,
  //           network: null,
  //           tag: null,
  //         },
  //         USDT: {
  //           QR: "https://firebasestorage.googleapis.com/v0/b/edge-finex.appspot.com/o/IMG_7289.jpegThu%20Sep%2019%202024%2013%3A04%3A28%20GMT%2B0300%20(Arabian%20Standard%20Time)?alt=media&token=81101809-1d06-4bb6-9d26-a8365daa2b1c",
  //           note: null,
  //           address: "0xAD0D457Bd090827999233bF10640915065d1117B",
  //           memo: null,
  //           symbol: "USDT",
  //           tag: null,
  //           name: "Tether",
  //           network: null,
  //         },
  //       },
  //     },
  //     Signals: {
  //       addresses: {},
  //       apiKey: "",
  //       type: "repeat",
  //       repeat: true,
  //     },
  //   })
  //     .then(() => {
  //       console.log("done");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  return (
    <MainContainer>
      {/* <button onClick={() => changeAddresses()}>change</button> */}
      {loader ? (
        <MainPage>
          <CircularLoader
            bg="rgba(12, 108, 243, 0.2)"
            size="44"
            color="#0C6CF2"
          />
        </MainPage>
      ) : (
        <>
          <Sidebar
            selected="Dashboard"
            hidden={{ sidebarHidden, setSidebarHidden }}
          />
          <Main sidebar={{ sidebarHidden, setSidebarHidden }} />
        </>
      )}
    </MainContainer>
  );
};

export default Dashboard;
